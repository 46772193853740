import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  OutboundLink,
  FadeReveal,
  multipleComponentIterator,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  MarkdownContent,
  BasicCarousel,
  componentIterator,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  SocialMediaV2,
  ReviewV2,
  CustomerReviewModal,
} from "@bluefin/components";
import { Grid, Button, Header, p, List, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"image hero image-overlay"}
            background={true}
          >
            <Grid
              className={"home-section-container"}
              stackable={true}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <Content textAlign={"center"}>
                  <Content.Markup
                    width={8}
                    isMarkdown={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_section_description",
                    })}
                    extra={
                      <div className={"ctas-container"}>
                        <Button
                          primary={true}
                          as={OutboundLink}
                          to={bookingUrl.url}
                          event={{
                            category: "Booking",
                            action: "Schedule Appointment Intent",
                          }}
                        >
                          {bookingUrl.title}
                        </Button>
                      </div>
                    }
                  />
                </Content>
              </Grid.Column>
            </Grid>
          </Image>
          <div className={"services-section"}>
            <Card.Group className={"services"} itemsPerRow={3} stackable={true}>
              <FadeReveal
                cascade={true}
                cascadeFactor={0.4}
                triggerOnce={true}
                className={"ui card"}
                disableAnimationOnMobile={true}
                noContainer={true}
                children={multipleComponentIterator({
                  iterator: allFishermanBusinessMenuCategory.nodes,
                  limit: 3,
                  components: [
                    {
                      component: (
                        <FullImageCard
                          link={true}
                          as={InternalLink}
                          event={{
                            category: "Services",
                            action: "View Category Details",
                          }}
                        />
                      ),
                      propMap: {
                        key: "_id",
                        image: withNamedArgs({
                          func: getBusinessFiles,
                          args: {
                            businessFiles: allFishermanBusinessFile.nodes,
                            numToSelect: 1,
                          },
                        }),
                        "event.label": "name",
                        to: createServiceCategorySlug,
                      },
                      children: [
                        {
                          component: <Card.Header />,
                          propMap: { content: "name", key: "name" },
                        },
                        {
                          component: <FishermanIcon iconName={"arrow-right"} />,
                        },
                      ],
                    },
                  ],
                })}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "View All Services" }}
              as={InternalLink}
            >
              See All Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "about_section_description",
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Image
                    className={"image"}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "about_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"trusted-by-section"}>
            <Grid stackable={true} columns={2}>
              <Grid.Column width={5} className={"copy-column"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "trusted_by_section_header",
                    defaultValue: "TRUSTED BY:",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={11} className={"images-column"}>
                <BasicCarousel
                  className={"image-gallery"}
                  size={4}
                  displayIndicators={false}
                  displayPlayPauseButton={true}
                  autoScroll={true}
                  animation={{
                    name: "blur",
                    duration: 200,
                    transitionProps: {
                      preAnimating: { opacity: 0.99, blur: "2px" },
                      animating: { opacity: 0.99, blur: "2px" },
                    },
                  }}
                  mobile={{ size: 2 }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                      componentIdentifier: "trusted_by_image_gallery",
                    }),
                    component: (
                      <Image
                        background={true}
                        className={"gallery-image-item"}
                      />
                    ),
                    propMap: { src: "__all__" },
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "OUR TOP STYLISTS",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"featured-stylists"} itemsPerRow={5}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={5}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/team/"}
              primary={true}
              event={{ category: "Team", action: "View All Team Members" }}
              as={InternalLink}
            >
              See All Stylists
            </Button>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <BasicCarousel
              className={"image-gallery"}
              size={4}
              grouping={2}
              displayIndicators={true}
              displayPlayPauseButton={true}
              autoScroll={true}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: (
                  <Image background={true} className={"gallery-image-item"} />
                ),
                propMap: { src: "__all__" },
              })}
            />
          </div>
          <div className={"reviews-section"}>
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
              />
            </div>
          </div>
          <Grid
            stackable={true}
            className={"loyalty-section"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Header as={"h2"} content={"Loyalty"} />
              <p>
                At Color Lush, we value you as a guest! To show appreciation and
                gratitude, we created a system that gives back!
              </p>
              <Grid
                stackable={true}
                className={""}
                verticalAlign={"middle"}
                textAlign={"center"}
              >
                <Grid.Column width={14} textAlign={"center"}>
                  <Grid
                    stackable={true}
                    className={"component-section-container custom-banner"}
                    verticalAlign={"top"}
                    textAlign={"center"}
                    columns={"equal"}
                  >
                    <Grid.Row streched={true}>
                      <Grid.Column>
                        <Image
                          size={"mini"}
                          centered={true}
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/heart.png"
                          }
                        />
                        <h4>1 point per dollar you spend</h4>
                        <p>On both services & home care products</p>
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          size={"mini"}
                          centered={true}
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/heart.png"
                          }
                        />
                        <h4>Turn points back into dollars</h4>
                        <List
                          items={[
                            "500 points you get $10 off",
                            "1000 points you get $20 off",
                          ]}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          size={"mini"}
                          centered={true}
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/heart.png"
                          }
                        />
                        <h4>It's that easy!</h4>
                        <p>
                          Use discounts today or save them for future purchases
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <div className={"product-section"}>
            <Grid
              stackable={true}
              doubling={true}
              columns={2}
              reversed={"mobile"}
            >
              <Grid.Column width={8} className={"image-column"}>
                <div className={"image-container"}>
                  <BasicCarousel
                    className={""}
                    slide={true}
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    animation={{
                      name: "blur",
                      duration: 200,
                      transitionProps: {
                        animating: { blur: "2px", opacity: 0.99 },
                        preAnimating: { blur: "2px", opacity: 0.99 },
                      },
                    }}
                    mobile={{ size: 1 }}
                    items={[
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/KHO_HairTreatment_100ml_dropshadow.webp"
                          }
                          alt={"Hair Treatment"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header as={"h3"} content={"Hair Treatment"} />
                          </div>
                          <div>
                            <p />
                            <Header as={"h4"} content={"Keratin Healing Oil"} />
                            <Button
                              primary={true}
                              to={
                                "https://www.lanza.com/products/keratin-healing-oil-hair-treatment?view=product"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/HCCTraumaTreatment150ml.webp"
                          }
                          alt={"Trauma Treatment"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header as={"h3"} content={"Trauma Treatment"} />
                          </div>
                          <div>
                            <p />
                            <Header as={"h4"} content={"Healing Colorcare"} />
                            <Button
                              primary={true}
                              to={
                                "https://www.lanza.com/products/healing-colorcare-trauma-treatment?_pos=1&_sid=5258356c4&_ss=r"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/b74a6b31-60fd-4903-9adf-f863f8fedef0/kho_lustrous_shinespray_100ml_dropshadow.webp"
                          }
                          alt={"Lustrous Shine Spray"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Lustrous Shine Spray"}
                            />
                          </div>
                          <div>
                            <p />
                            <Header
                              as={"h4"}
                              content={
                                "Radiant Shine, UV Protection, and Frizz Control"
                              }
                            />
                            <Button
                              primary={true}
                              to={
                                "https://www.lanza.com/products/lanza-keratin-healing-oil-lustrous-shine-spray"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={"product-details-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Header as={"h2"} content={"Shine & Nourish"} />
                  <p>
                    Keep your hair smooth, hydrated, and full of life with our
                    expert care solutions. Our premium treatments are designed
                    to restore moisture, reduce frizz, and enhance shine,
                    leaving your hair looking and feeling its best every day.
                  </p>
                  <Button
                    primary={true}
                    to={"/contact/"}
                    as={InternalLink}
                    content={"Call or Visit Us to Purchase"}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
